<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
             <div class="back-title-container">
                <BackButton />
                <h1 class="title">{{ $t('candidates.title') }}</h1>
            </div>
        </div>

        <WorkProviderOfferCandidateCards :candidates="candidates" :loaded="loaded" />
    </layout>
</template>

<script>
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import BackButton from '../../../../components/Navigation/BackButton.vue';

import WorkProviderOfferCandidateCards from '../../../../components/Tables/WorkProviderOfferCandidateCards.vue'

export default {
    name: 'WorkProviderOfferCandidates',
    
    components: {
        Layout,
        BackButton,
        WorkProviderOfferCandidateCards
    },

    props: {
        id: {
            type: String,
            default: null
        },

        stage: {
            type: String,
            default: null
        },

        type: {
            type: String,
            default: null
        },

        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'work-provider-offer-candidates',
            classes: { dashboard: true, 'work-provider-offer-candidates': true },

            candidates: [],
            loaded: false
        }
    },

    methods: {
        getCandidates: function() {
            const self = this;
            
            self.$axios
                .get(`work-provider/offers/candidates`)
                .then(response => {
                    self.loaded = true;
                    if(response.status == 200) {
                        self.candidates = response.data;
                        
                        if(self.candidates.length !== 0) {
                            self.candidates = self.candidates.sort((a, b) => {
                                return new Date(b.sort_date) - new Date(a.sort_date);
                            })
                        }
                        
                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    self.loaded = true;
                    
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this; self.getCandidates();
    }
}
</script>

