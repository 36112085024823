<template>
    <div id="with-sticky-header" class="work-provider-offer-candidates-cards with-sticky-header">
        <div class="filter-container" v-if="loaded && candidates.length !== 0">
            <div class="multi-selects">
                <div class="multi-select-container">
                    <p class="label">{{ $t('candidates.filters.locations.label') }}</p>

                    <multiselect    v-model="location"
                                    id="location"
                                    :options="locations"
                                    :close-on-select="true"
                                    :multiple="false"
                                    :allowEmpty="false"
                                    :searchable="false"

                                    :select-label="$t('language.multiselect.label.select')"
                                    :selected-label="$t('language.multiselect.label.selected')"
                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                    :custom-label="translate_location">
                    </multiselect>
                </div>  

                <div class="multi-select-container">
                    <p class="label">{{ $t('candidates.filters.offers.label') }}</p>

                    <multiselect    v-model="offer"
                                    id="offer"
                                    :options="offers"
                                    :close-on-select="true"
                                    :multiple="false"
                                    :allowEmpty="false"
                                    :searchable="false"

                                    :select-label="$t('language.multiselect.label.select')"
                                    :selected-label="$t('language.multiselect.label.selected')"
                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                    :custom-label="translate_offers">
                    </multiselect>
                </div>
                
                <div class="multi-select-container stage">
                    <p class="label">{{ $t('candidates.filters.stages.label') }}</p>
                    
                    <multiselect    v-model="stage"
                                    id="stage"
                                    :options="stages"
                                    :close-on-select="true"
                                    :multiple="false"
                                    :allowEmpty="false"
                                    :searchable="false"

                                    :select-label="$t('language.multiselect.label.select')"
                                    :selected-label="$t('language.multiselect.label.selected')"
                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                    :custom-label="translate_stages">
                    </multiselect>
                </div>

                <span class="cta cta-form light" @click="reset_filters()"><font-awesome-icon :icon="['fas', 'arrows-rotate']" /></span>
            </div>
        </div>

        <div v-if="loaded">
            <div v-if="candidates.length === 0" class="card empty"><p class="bold">{{ $t('candidates.card.empty') }}</p></div>

            <div v-else-if="candidates.length !== 0 && filtered_leads.length === 0" class="card empty empty-filter">
                <p>{{ $t('candidates.card.empty_filters') }}</p>
                <span @click="reset_filters()" class="link">{{ $t('candidates.card.reinit') }}</span>
            </div>

            <div v-else>
                <div v-for="lead in filtered_leads" :key="lead._id" :class="['card', lead.stage]" @click="open_messaging(lead.user_id, lead.number_of_unread_messages)">
                    <div class="card-heading">
                        <p class="title">{{ lead.name === 'anonymous' ? $t('messages.info.work_provider.header.anonymous') : lead.name }}</p>
                        <p v-if="lead.stage === 'interested' && lead.availableNow" class="availability" v-html="$t('candidates.card.availability.now')"></p>
                        <p v-else-if="lead.stage === 'interested' && !lead.availableNow" class="availability" v-html="$t('candidates.card.availability.future', { date: $moment(lead.nextTimeAvailable).format('DD/MM/YYYY') })"></p>
                        
                        <span v-else-if="lead.number_of_unread_messages !== 0" class="bubble bubble-outline success animate__animated animate__flash animate__repeat-2" v-html="$t(lead.number_of_unread_messages === 1 ? 'candidates.card.message' : 'candidates.card.messages', { count: lead.number_of_unread_messages })"></span>
                        <span v-else v-html="$t('candidates.card.messages', { count: lead.number_of_unread_messages })"></span>
                    </div>
                    
                    <div class="card-body" v-if="lead.stage === 'interested'">
                        <div :class="['multi-offer-container', { single: lead.offers.length === 1 }]">
                            <div class="row bottom">
                                <div class="card" v-for="offer in lead.offers" :key="offer._id">
                                    <div class="card-heading"><p>{{ offer.offer_name }}</p></div>
                                    <div class="card-body">
                                        <div class="locations-container">
                                            <div class="row locations">
                                                <span v-for="location in offer.locations" :key="location._id" :class="['bubble', location.selected ? 'success' : '']">{{ location.name }}</span>
                                            </div>

                                            <div class="row">
                                                <span class="bold label">{{ $t('candidates.card.interested_since') }}</span>
                                                <span class="history">{{ offer.history.interested | moment("DD/MM/YYYY, H:mm") }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-body" v-else>
                        <div class="row">
                            <span v-html="$t('candidates.card.status', { date: $moment(lead.offers[0].history.unlocked).format('DD/MM/YYYY, HH:mm') })"></span>
                            
                            <span v-if="lead.last_communication && lead.last_communication.time && lead.last_communication.destination && lead.last_communication.destination === 'work_provider'" v-html="$t('candidates.card.last_comm.him', { date: $utils._return_time_diff_string(lead.last_communication.time) })"></span>
                            <span v-else-if="lead.last_communication && lead.last_communication.time && lead.last_communication.destination && lead.last_communication.destination === 'person'" v-html="$t('candidates.card.last_comm.you', { date: $utils._return_time_diff_string(lead.last_communication.time) })"></span>
                        </div>
                        
                        <div :class="['multi-offer-container', { single: lead.offers.length === 1 }]">
                            <div class="row bottom">
                                <div class="card" v-for="offer in lead.offers" :key="offer._id">
                                    <div class="card-heading"><p>{{ offer.offer_name }}</p></div>
                                    <div class="card-body">
                                        <div class="locations-container">
                                            <div class="row locations">
                                                <span v-for="location in offer.locations" :key="location._id" :class="['bubble', location.selected ? 'success' : '']">{{ location.name }}</span>
                                            </div>

                                            <div class="row">                                                <span class="bold label">{{ $t('candidates.card.interested_since') }}</span>
                                                <span class="history">{{ offer.history.interested | moment("DD/MM/YYYY, H:mm") }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="card loading w-auto"></div>
    </div>
</template>

<script>
export default {
    name: 'WorkProviderOfferCandidateCards',

    data() {
        return {
            location: null,
            locations: [],

            offer: null,
            offers: [],

            stage: 'all',
            stages: ['all', 'interested', 'unlocked', 'not_unlocked'],

            filtered_leads: [],
            filter: { location: 'all', stage: 'all', offer: 'all' },

            loaded: false
        }
    },

    props: {
        candidates: {
            type: Array,
            default() {
                return []
            }
        },
    },

    watch: {
        stage: function() {
            const self = this;

            if(self.loaded) {
                self.filter.stage = self.stage;
                self.compute_filter();
            }
        },

        location: function() {
            const self = this;

            if(self.loaded) {
                if(self.location._id === 1) { self.filter.location = 'all';}
                else { self.filter.location = self.location; }
                self.compute_filter();
            }
        },

        offer: function() {
            const self = this;

            if(self.loaded) {
                if(self.offer._id === 1) { self.filter.offer = 'all';}
                else { self.filter.offer = self.offer; }
                self.compute_filter();
            }
        },

        candidates: function() {
            const self = this;
            self.filtered_leads = self.candidates;

            self.$nextTick(() => {
                self.loaded = true;
            })
        }
    },

    methods: {
        reset_filters: function() {
            const self = this;
            self.filter = { location: 'all', stage: 'all', offer: 'all' };
            self.offer = self.offers.find(offer => offer._id === 1);
            self.location = self.locations.find(offer => offer._id === 1);
            self.stage = 'all';

            self.compute_filter();
        },

        compute_filter: function() {
            const self = this;
            if(self.filter.location === 'all') delete self.filter.location;
            if(self.filter.stage === 'all') delete self.filter.stage;
            if(self.filter.offer === 'all') delete self.filter.offer;

            self.filtered_leads = self.candidates.filter(function(item) {
                let valid = true;
                for (var key in self.filter) {
                    if(key === 'location') {
                        const s = item.selected_location_ids.some(function(location_id) {
                            return location_id.toString() === self.filter[key]._id.toString();
                        });
                        
                        if(!s) valid = false;
                    } else if(key === 'offer') {
                        if(item.offer_ids.includes(self.filter[key].info._id.toString())) valid = false;
                    } else if (item[key] === undefined || item[key] != self.filter[key])  valid = false;
                }
                
                return valid;
            });
        },

        open_messaging: function(destination_user_id) {
            const self = this;
            self.$utils._navigate_to_name_with_params(`work-provider-message`, { destination_user_id })
        },

        translate_stages: function(stage) {
            const self = this;
            return self.$t(`candidates.filters.stages.options.${stage}`)
        },

        translate_location: function(location) {
            const self = this;

            if(location._id === 1) { return self.$t(`candidates.filters.locations.placeholder`) }
            return `${location.name}`;
        },

        translate_offers: function(offer) {
            const self = this;

            if(offer._id === 1) { return self.$t(`candidates.filters.offers.placeholder`) }
            return `${offer.info.name}`;
        },

        get_locations: function() {
            const self = this;

            self.$axios
                .get(`locations`)
                .then(response => {
                    if(response.status === 200){
                        self.locations = [{ _id: 1, label: 'all' }];
                        self.locations.push(...response.data.locations)

                        self.location = self.locations[0];
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        get_offers: function() {
            const self = this;

            self.$axios
                .get(`work-provider/offers/paid/published`)
                .then(response => {
                    if(response.status == 200){
                        self.offers = [{ _id: 1, label: 'all' }];
                        self.offers.push(...response.data)

                        self.offer = self.offers[0];
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this;
        self.get_locations();
        self.get_offers();
    }
}
</script>

